<template>
	<component
		:is="nodeType"
		:href="(!isNuxt && href) || undefined"
		:to="to"
		class="link"
		:class="classes"
		v-bind="$attrs"
		tabindex="0"
	>
		<TnIcon
			v-if="arrowLeft"
			name="cta-left"
			:size="size || 'm'"
			class="link--arrow__left"
		/>
		<span class="link--text">
			<slot>{{ text }}</slot>
		</span>
		<TnIcon
			v-if="arrowRight"
			name="cta-right"
			:size="iconSize || 'm'"
			class="link--arrow__right"
		/>
	</component>
</template>

<script>
import { defineComponent, resolveComponent } from "vue";

import sizes from "./definitions/sizes";

/**
 * TnLink ...
 * @displayName TnLink
 */
export default defineComponent({
	name: "TnLink",

	props: {
		/**
		 * Text/label of link, will be overwritten by the default slot if passing in text/content there instead
		 */
		text: {
			type: String,
			default: "",
		},
		/**
		 * If set, will dynamically make the component node type either a **router-link** or **nuxt-link** (depending on app context)
		 */
		to: {
			type: String,
		},
		/**
		 * If set, will render the component node as **\<a>**-tag for linking with href
		 */
		href: {
			type: String,
		},
		/**
		 * Size of the link
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Placeholder
		 */
		arrowRight: {
			type: Boolean,
			default: false,
		},
		/**
		 * Placeholder
		 */
		arrowLeft: {
			type: Boolean,
			default: false,
		},
		/**
		 * Placeholder
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Placeholder
		 */
		block: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		nodeType() {
			if (this.to && this.$nuxt) return resolveComponent("NuxtLink");
			if (this.to && this.$router) return resolveComponent("RouterLink");
			return "a";
		},
		classes() {
			return {
				[`link--${this.size}`]: this.size,
				"link--dark": this.dark,
				"link--block": this.block,
			};
		},
		iconSize() {
			return this.size === "l" ? "l" : "m";
		},
		isNuxt() {
			return !!this.$nuxt;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;

.link {
	--link-color: #{variables.$color-cta-default};
	--link-hover-color: #{variables.$color-cta-hover};
	--link-active-color: #{variables.$color-cta-active};
	--link-focus-color: #{variables.$color-cta-focus};
	--link-arrow-margin: 8px;

	color: var(--link-color);
	cursor: pointer;
	text-decoration: none;
	padding: 4px 0;
	display: inline-flex;
	align-items: center;

	a {
		color: inherit;
		text-decoration: inherit;

		&:hover,
		&:active,
		&:focus {
			outline: none;
			border: 0;
		}
	}

	&:hover {
		color: var(--link-hover-color);
		text-decoration: underline;
		text-underline-offset: 4px;

		.link--arrow__right {
			transform: translateX(4px);
			transition: all 0.2s ease-out;
		}

		.link--arrow__left {
			transform: translateX(-4px);
			transition: all 0.2s ease-out;
		}
	}

	&:active {
		color: var(--link-active-color);
		text-decoration: none;
	}

	&:focus-visible {
		outline: 2px solid var(--link-focus-color);
		border-radius: 4px;
	}

	&--s {
		--link-arrow-margin: 4px;

		@extend %font-text-link-s;
	}

	&--m {
		@extend %font-text-link-m;
	}

	&--l {
		@extend %font-text-link-l;
	}

	&--arrow {
		&__right {
			margin-left: var(--link-arrow-margin);
		}

		&__left {
			margin-right: var(--link-arrow-margin);
		}

		&__right,
		&__left {
			vertical-align: middle;
			display: inline-block;
		}
	}

	&--block {
		display: block;
	}

	&--dark {
		--link-color: #{variables.$color-cta-dark-default};
		--link-hover-color: #{variables.$color-cta-dark-hover};
		--link-active-color: #{variables.$color-cta-dark-active};
		--link-focus-color: #{variables.$color-cta-dark-focus};
	}
}
</style>
